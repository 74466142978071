import React, { useEffect, useState } from "react";
// import "./ModalAddPackage.css";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCouponRequest } from "../../../redux/Actions/Coupon/AddCouponAction";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { modalHeader } from "./ModalConstants";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { AddYuvaClinicRequest } from "../../../redux/Actions/Clinic/AddYuvaClinicAction";
const regex = /^[a-zA-Z0-9]*$/;
const loginSchema = Yup.object().shape({
  ClinicName: Yup.string().required("Required Field"),
  mapUrl: Yup.string().required("Required Field"),
});





function ModalAddYuvaClinic(props) {
  const [address, setAddress] = useState("");
  const [city,setCity]=useState();
  const [flag, setFlag] = useState(false);

  

  const viewallCity = useSelector((state) => state.viewAllCity);

  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, []);

 

  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  const dispatch = useDispatch();


  function handleCity(e){
    setCity(parseInt(e.target.value));
  }

  



  const updateRes = useSelector((state) => state.AddCoupon);

  useEffect(() => {
    if (updateRes?.data || updateRes?.error) {
      setFlag(true);
    }
  }, [updateRes]);

  const handleSubmit = (values, { setSubmitting }) => {
    
    if (address==="") {
      Swal.fire({
        title: "Please Add  Address",
        icon: "error",
      });
    } 
    else if(!city){
      Swal.fire({
        title: "Please Select City",
        icon: "error",
      });
    }
    else {
      setFlag(true);

      let data = {
        ClinicName: values.ClinicName,
        mapUrl: values.mapUrl,
        address: address,
        city:city
      };
      dispatch(AddYuvaClinicRequest(data));
    }
  };

  const AddYuvaClinic = useSelector((state) => state.AddYuvaClinic);
console.log("AddYuvaClinic",AddYuvaClinic);

  useEffect(() => {
    if (
      AddYuvaClinic?.data?.data.status === 201 &&
      AddYuvaClinic?.data?.data.data.message !== ""
    ) {
      Swal.fire({
        title: "Success",
        text: `${AddYuvaClinic?.data?.data.data.message}`,
        icon: "success",
        zIndex: 9999,
      }).then(() => {
        setFlag(false);
        handleClose();
      });
    }

   
  }, [AddYuvaClinic]);

  


 

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Add Yuva Clinic
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              ClinicName: "",
              mapUrl: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="text"
                      name="ClinicName"
                      placeholder="Clinic Name"
                      className="inputField"
                    // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="ClinicName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="text"
                      name="mapUrl"
                      placeholder="Map Url"
                      className="inputField"
                    // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="mapUrl"
                      component="div"
                    />
                  </label>
                  <label>

                    <select
                      onChange={(e) => handleCity(e)}
                      name="city"
                      className="inputField"
                    >

                      <option value="" disabled selected>
                        Select City
                      </option>

                      {viewallCity?.data?.data?.data?.data?.map(
                        (item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      )}
                    </select>
                  </label>
                  <label>
                    <textarea
                      placeholder="Address"
                      rows={5}
                      onChange={(e) => setAddress(e.target.value)}
                      className="CouponDescription"
                    />
                  </label>

                  <button
                    disabled={flag}
                    type="submit"
                    className="modalButtonPackage modalCouponButton"
                  >
                    {flag ? <CircularProgress /> : "Add Yuva Clinic"  }
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddYuvaClinic;
