import * as types from "../../Config/actionType"

export const AddYuvaClinicRequest = (data) => {
    return {
        type: types.ADD_YUVA_CLINIC_REQUEST,
        data:data,
    }
}

export const AddYuvaClinicSuccess = (data) => {
    return {
        type: types.ADD_YUVA_CLINIC_SUCCESS,
        data:data,
    }
}

export const AddYuvaClinicFailure = (data) => {
    return {
        type: types.ADD_YUVA_CLINIC_FAILURE,
        data:data,
    }
}