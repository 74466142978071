import * as types from "../../Config/actionType"

export const VerifyUserKycRequest = (data) => {
    return {
        type: types.VERIFY_USER_KYC_REQUEST,
        data:data,
    }
}

export const VerifyUserKycSuccess = (data) => {
    return {
        type: types.VERIFY_USER_KYC_SUCCESS,
        data:data,
    }
}

export const VerifyUserKycFailure = (data) => {
    return {
        type: types.VERIFY_USER_KYC_FAILURE,
        data:data,
    }
}