import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Clinic/VerifyUserKycAction"
import NetworkService from "../../../network/YuvaClinic/YuvaClinicNetworkService";

export function* VerifyUserKycSaga(action) {
  try {
    let response = yield call(NetworkService.VerifyUserKyc, action);
    yield put(actions.VerifyUserKycSuccess(response));
  } catch (err) {
    yield put(actions.VerifyUserKycFailure(err));
  }
}

export function* watchVerifyUserKycSaga() {
  yield takeLatest(types.VERIFY_USER_KYC_REQUEST, VerifyUserKycSaga);
}