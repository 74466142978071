import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Clinic/viewAllYuvaClinicAction";
import NetworkService from "../../../network/YuvaClinic/YuvaClinicNetworkService";

export function* viewAllYuvaClinic(action) {
  try {
    let response = yield call(NetworkService.viewAllYuvaClinic,action);
    yield put(actions.viewAllYuvaClinicSuccess(response))
  } catch (err) {
    yield put(actions.viewAllYuvaClinicFailure(err));
  }
}

export function* watchViewAllYuvaClinic() {
  yield takeLatest(types.VIEW_ALL_YUVA_CLINIC_REQUEST, viewAllYuvaClinic);
}