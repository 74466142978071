import RequestService from "../RequestService";
import { api } from "../../helpers/YuvaClinic/YuvaClinicConstant";

class NetworkService {
    AddYuvaClinic(action) {
        const endPoint = `${api.AddYuvaClinic}`;
        return RequestService.postRequest(endPoint, {
            address: action.data.address,
            cityId: action.data.city,
            clinicName: action.data.ClinicName,
            mapUrl: action.data.mapUrl
          });
    }

    viewAllYuvaClinic(action) {
        const endPoint = `${api.viewAllYuvaClinic}?enumForYuvaClinic=YUVA_CLINIC&pageNo=${action.data.page}&pageSize=${action.data.pageSize}&sortBy=ID&sortOrder=ASC`;
        return RequestService.postRequest(endPoint, {
          cityList: action.data.city,
          search: action.data.searchText
        });
      }

      VerifyUserKyc(action) {
        const endPoint = `${api.VerifyUserKyc}?uniqueId=${action.data}`;
        return RequestService.postRequest(endPoint);
      }

}
export default new NetworkService();




