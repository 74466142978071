export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const LOGIN_INSURANCE_AGENT_REQUEST = "LOGIN_REQUEST"
export const LOGIN_INSURANCE_AGENT_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_INSURANCE_AGENT_FAILURE = "LOGIN_FAILURE"

export const ADD_INITIATE_REFUND_REQUEST = "ADD_INITIATE_REFUND_REQUEST"
export const ADD_INITIATE_REFUND_SUCCESS = "ADD_INITIATE_REFUND_SUCCESS"
export const ADD_INITIATE_REFUND_FAILURE = "ADD_INITIATE_REFUND_FAILURE"

export const ADD_DOCTORS_REQUEST = "ADD_DOCTORS_REQUEST"
export const ADD_DOCTORS_SUCCESS = "ADD_DOCTORS_SUCCESS"
export const ADD_DOCTORS_FAILURE = "ADD_DOCTORS_FAILURE"

export const DELETE_DOCTOR_REQUEST = "DELETE_DOCTOR_REQUEST"
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS"
export const DELETE_DOCTOR_FAILURE = "DELETE_DOCTOR_FAILURE"

export const ADD_HOSPITAL_REQUEST = "ADD_HOSPITAL_REQUEST"
export const ADD_HOSPITAL_SUCCESS = "ADD_HOSPITAL_SUCCESS"
export const ADD_HOSPITAL_FAILURE = "ADD_HOSPITAL_FAILURE"

export const UPDATE_HOSPITAL_REQUEST = "UPDATE_HOSPITAL_REQUEST"
export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS"
export const UPDATE_HOSPITAL_FAILURE = "UPDATE_HOSPITAL_FAILURE"

export const VIEW_ALL_HOSPITAL_NAMES_REQUEST = "VIEW_ALL_HOSPITAL_NAMES_REQUEST"
export const VIEW_ALL_HOSPITAL_NAMES_SUCCESS = "VIEW_ALL_HOSPITAL_NAMES_SUCCESS"
export const VIEW_ALL_HOSPITAL_NAMES_FAILURE = "VIEW_ALL_HOSPITAL_NAMES_FAILURE"

export const VIEW_ALL_DOCTORS_REQUEST = "VIEW_ALL_DOCTORS_REQUEST"
export const VIEW_ALL_DOCTORS_SUCCESS = "VIEW_ALL_DOCTORS_SUCCESS"
export const VIEW_ALL_DOCTORS_FAILURE = "VIEW_ALL_DOCTORS_FAILURE"

export const UPDATE_DOCTOR_REQUEST = "UPDATE_DOCTOR_REQUEST"
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS"
export const UPDATE_DOCTOR_FAILURE = "UPDATE_DOCTOR_FAILURE"

export const VIEW_ALL_HOSPITAL_REQUEST = "VIEW_ALL_HOSPITAL_REQUEST"
export const VIEW_ALL_HOSPITAL_SUCCESS = "VIEW_ALL_HOSPITAL_SUCCESS"
export const VIEW_ALL_HOSPITAL_FAILURE = "VIEW_ALL_HOSPITAL_FAILURE"

export const VIEW_HOSPITAL_DETAIL_REQUEST = "VIEW_HOSPITAL_DETAIL_REQUEST"
export const VIEW_HOSPITAL_DETAIL_SUCCESS = "VIEW_HOSPITAL_DETAIL_SUCCESS"
export const VIEW_HOSPITAL_DETAIL_FAILURE = "VIEW_HOSPITAL_DETAIL_FAILURE"

export const VIEW_ALL_AGENTS_REQUEST = "VIEW_ALL_AGENTS_REQUEST"
export const VIEW_ALL_AGENTS_SUCCESS = "VIEW_ALL_AGENTS_SUCCESS"
export const VIEW_ALL_AGENTS_FAILURE = "VIEW_ALL_AGENTS_FAILURE"

export const DELETE_HOSPITAL_REQUEST = "DELETE_HOSPITAL_REQUEST"
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS"
export const DELETE_HOSPITAL_FAILURE = "DELETE_HOSPITAL_FAILURE"

export const VIEW_ALL_CORPORATE_REQUEST = "VIEW_ALL_CORPORATE_REQUEST"
export const VIEW_ALL_CORPORATE_SUCCESS = "VIEW_ALL_CORPORATE_SUCCESS"
export const VIEW_ALL_CORPORATE_FAILURE = "VIEW_ALL_CORPORATE_FAILURE"

export const ADD_CORPORATE_REQUEST = "ADD_CORPORATE_REQUEST"
export const ADD_CORPORATE_SUCCESS = "ADD_CORPORATE_SUCCESS"
export const ADD_CORPORATE_FAILURE = "ADD_CORPORATE_FAILURE"

export const UPDATE_CORPORATE_REQUEST = "UPDATE_CORPORATE_REQUEST"
export const UPDATE_CORPORATE_SUCCESS = "UPDATE_CORPORATE_SUCCESS"
export const UPDATE_CORPORATE_FAILURE = "UPDATE_CORPORATE_FAILURE"

export const DELETE_CORPORATE_REQUEST = "DELETE_CORPORATE_REQUEST"
export const DELETE_CORPORATE_SUCCESS = "DELETE_CORPORATE_SUCCESS"
export const DELETE_CORPORATE_FAILURE = "DELETE_CORPORATE_FAILURE"

export const VIEW_CORPORATE_DETAIL_REQUEST = "VIEW_CORPORATE_DETAIL_REQUEST"
export const VIEW_CORPORATE_DETAIL_SUCCESS = "VIEW_CORPORATE_DETAIL_SUCCESS"
export const VIEW_CORPORATE_DETAIL_FAILURE = "VIEW_CORPORATE_DETAIL_FAILURE"

export const VIEW_AGENT_DETAIL_REQUEST = "VIEW_AGENT_DETAIL_REQUEST"
export const VIEW_AGENT_DETAIL_SUCCESS = "VIEW_AGENT_DETAIL_SUCCESS"
export const VIEW_AGENT_DETAIL_FAILURE = "VIEW_AGENT_DETAIL_FAILURE"

export const DELETE_AGENT_REQUEST = "DELETE_AGENT_REQUEST"
export const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS"
export const DELETE_AGENT_FAILURE = "DELETE_AGENT_FAILURE"

export const ADD_INSURANCE_AGENTS_REQUEST = "ADD_INSURANCE_AGENTS_REQUEST"
export const ADD_INSURANCE_AGENTS_SUCCESS = "ADD_INSURANCE_AGENTS_SUCCESS"
export const ADD_INSURANCE_AGENTS_FAILURE = "ADD_INSURANCE_AGENTS_FAILURE"

export const UPDATE_INSURANCE_AGENT_REQUEST = "UPDATE_INSURANCE_AGENT_REQUEST"
export const UPDATE_INSURANCE_AGENT_SUCCESS = "UPDATE_INSURANCE_AGENT_SUCCESS"
export const UPDATE_INSURANCE_AGENT_FAILURE = "UPDATE_INSURANCE_AGENT_FAILURE"

export const VIEW_ACCOUNT_REQUEST = "VIEW_ACCOUNT_REQUEST"
export const VIEW_ACCOUNT_SUCCESS = "VIEW_ACCOUNT_SUCCESS"
export const VIEW_ACCOUNT_FAILURE = "VIEW_ACCOUNT_FAILURE"

export const VIEW_ALL_APPOINTMENTS_REQUEST = "VIEW_ALL_APPOINTMENTS_REQUEST"
export const VIEW_ALL_APPOINTMENTS_SUCCESS = "VIEW_ALL_APPOINTMENTS_SUCCESS"
export const VIEW_ALL_APPOINTMENTS_FAILURE = "VIEW_ALL_APPOINTMENTS_FAILURE"

export const VIEW_APPOINTMENTS_REQUEST = "VIEW_APPOINTMENTS_REQUEST"
export const VIEW_APPOINTMENTS_SUCCESS = "VIEW_APPOINTMENTS_SUCCESS"
export const VIEW_APPOINTMENTS_FAILURE = "VIEW_APPOINTMENTS_FAILURE"

export const ADD_APPOINTMENT_REQUEST = "ADD_APPOINTMENT_REQUEST"
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS"
export const ADD_APPOINTMENT_FAILURE = "ADD_APPOINTMENT_FAILURE"

export const UPDATE_APPOINTMENTS_REQUEST = "UPDATE_APPOINTMENTS_REQUEST"
export const UPDATE_APPOINTMENTS_SUCCESS = "UPDATE_APPOINTMENTS_SUCCESS"
export const UPDATE_APPOINTMENTS_FAILURE = "UPDATE_APPOINTMENTS_FAILURE"

export const DELETE_APPOINTMENTS_REQUEST = "DELETE_APPOINTMENTS_REQUEST"
export const DELETE_APPOINTMENTS_SUCCESS = "DELETE_APPOINTMENTS_SUCCESS"
export const DELETE_APPOINTMENTS_FAILURE = "DELETE_APPOINTMENTS_FAILURE"

export const CONFIRM_APPOINTMENT_REQUEST = "CONFIRM_APPOINTMENT_REQUEST"
export const CONFIRM_APPOINTMENT_SUCCESS = "CONFIRM_APPOINTMENT_SUCCESS"
export const CONFIRM_APPOINTMENT_FAILURE = "CONFIRM_APPOINTMENT_FAILURE"

export const RESCHEDULE_APPOINTMENT_SUCCESS = "RESCHEDULE_APPOINTMENT_SUCCESS"
export const RESCHEDULE_APPOINTMENT_REQUEST = "RESCHEDULE_APPOINTMENT_REQUEST"
export const RESCHEDULE_APPOINTMENT_FAILURE = "RESCHEDULE_APPOINTMENT_FAILURE"

export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS"
export const CANCEL_APPOINTMENT_REQUEST = "CANCEL_APPOINTMENT_REQUEST"
export const CANCEL_APPOINTMENT_FAILURE = "CANCEL_APPOINTMENT_FAILURE"

export const VIEW_SERVICE_REQUEST = "VIEW_SERVICE_REQUEST"
export const VIEW_SERVICE_FAILURE = "VIEW_SERVICE_FAILURE"
export const VIEW_SERVICE_SUCCESS = "VIEW_SERVICE_SUCCESS"

export const VIEW_SERVICE_REQUEST1 = "VIEW_SERVICE_REQUEST1"
export const VIEW_SERVICE_FAILURE1 = "VIEW_SERVICE_FAILURE1"
export const VIEW_SERVICE_SUCCESS1 = "VIEW_SERVICE_SUCCESS1"

export const VIEW_SERVICE_REQUEST2 = "VIEW_SERVICE_REQUEST2"
export const VIEW_SERVICE_FAILURE2 = "VIEW_SERVICE_FAILURE2"
export const VIEW_SERVICE_SUCCESS2 = "VIEW_SERVICE_SUCCESS2"

export const VIEW_ALL_SERVICE_REQUEST = "VIEW_ALL_SERVICE_REQUEST"
export const VIEW_ALL_SERVICE_FAILURE = "VIEW_ALL_SERVICE_FAILURE"
export const VIEW_ALL_SERVICE_SUCCESS = "VIEW_ALL_SERVICE_SUCCESS"

export const ADD_PROGRAM_REQUEST = "ADD_PROGRAM_REQUEST"
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS"
export const ADD_PROGRAM_FAILURE = "ADD_PROGRAM_FAILURE"

export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_FAILURE = "DELETE_PROGRAM_FAILURE"

export const GET_PROGRAM_REQUEST = "GET_PROGRAM_REQUEST"
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS"
export const GET_PROGRAM_FAILURE = "GET_PROGRAM_FAILURE"

export const VIEW_PROGRAM_REQUEST = "VIEW_PROGRAM_REQUEST"
export const VIEW_PROGRAM_SUCCESS = "VIEW_PROGRAM_SUCCESS"
export const VIEW_PROGRAM_FAILURE = "VIEW_PROGRAM_FAILURE"

export const LOGIN_LAB_AGENT_REQUEST = "LOGIN_LAB_AGENT_REQUEST"
export const LOGIN_LAB_AGENT_SUCCESS = "LOGIN_LAB_AGENT_SUCCESS"
export const LOGIN_LAB_AGENT_FAILURE = "LOGIN_LAB_AGENT_FAILURE"

export const VIEW_ALL_PROGRAM_REQUEST = "VIEW_ALL_PROGRAM_REQUEST"
export const VIEW_ALL_PROGRAM_SUCCESS = "VIEW_ALL_PROGRAM_SUCCESS"
export const VIEW_ALL_PROGRAM_FAILURE = "VIEW_ALL_PROGRAM_FAILURE"

export const LOGIN_HR_SUCCESS = "LOGIN_HR_SUCCESS"
export const LOGIN_HR_REQUEST = "LOGIN_HR_REQUEST"
export const LOGIN_HR_FAILURE = "LOGIN_HR_FAILURE"

export const LOGIN_DOCTOR_SUCCESS = "LOGIN_DOCTOR_SUCCESS"
export const LOGIN_DOCTOR_REQUEST = "LOGIN_DOCTOR_REQUEST"
export const LOGIN_DOCTOR_FAILURE = "LOGIN_DOCTOR_FAILURE"

export const VIEW_ALL_EMPLOYEE_SUCCESS = "VIEW_ALL_EMPLOYEE_SUCCESS"
export const VIEW_ALL_EMPLOYEE_REQUEST = "VIEW_ALL_EMPLOYEE_REQUEST"
export const VIEW_ALL_EMPLOYEE_FAILURE = "VIEW_ALL_EMPLOYEE_FAILURE"

export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST"
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE"
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS"

export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST"
export const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE"
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS"

export const ADD_EMPLOYEE_FILE_REQUEST = "ADD_EMPLOYEE_FILE_REQUEST"
export const ADD_EMPLOYEE_FILE_FAILURE = "ADD_EMPLOYEE_FILE_FAILURE"
export const ADD_EMPLOYEE_FILE_SUCCESS = "ADD_EMPLOYEE_FILE_SUCCESS"

export const VIEW_EMPLOYEE_DETAIL_SUCCESS = "VIEW_EMPLOYEE_DETAIL_SUCCESS"
export const VIEW_EMPLOYEE_DETAIL_REQUEST = "VIEW_EMPLOYEE_DETAIL_REQUEST"
export const VIEW_EMPLOYEE_DETAIL_FAILURE = "VIEW_EMPLOYEE_DETAIL_FAILURE"

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST"
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE"
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS"

export const END_APPOINTMENT_REQUEST = "END_APPOINTMENT_REQUEST"
export const END_APPOINTMENT_FAILURE = "END_APPOINTMENT_FAILURE"
export const END_APPOINTMENT_SUCCESS = "END_APPOINTMENT_SUCCESS"

export const ADD_REPORT_REQUEST = "ADD_REPORT_REQUEST"
export const ADD_REPORT_FAILURE = "ADD_REPORT_FAILURE"
export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS"

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST"
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE"
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS"

export const GET_ALL_SERVICE_REQUEST = "GET_ALL_SERVICE_REQUEST"
export const GET_ALL_SERVICE_FAILURE = "GET_ALL_SERVICE_FAILURE"
export const GET_ALL_SERVICE_SUCCESS = "GET_ALL_SERVICE_SUCCESS"

export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST"
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE"
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS"

export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST"
export const DELETE_APPOINTMENT_FAILURE = "DELETE_APPOINTMENT_FAILURE"
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS"

export const UPDATE_SERVICE_DESCRIPTION_REQUEST = "UPDATE_SERVICE_DESCRIPTION_REQUEST"
export const UPDATE_SERVICE_DESCRIPTION_FAILURE = "UPDATE_SERVICE_DESCRIPTION_FAILURE"
export const UPDATE_SERVICE_DESCRIPTION_SUCCESS = "UPDATE_SERVICE_DESCRIPTION_SUCCESS"

export const UPDATE_SERVICE_TITLE_REQUEST = "UPDATE_SERVICE_TITLE_REQUEST"
export const UPDATE_SERVICE_TITLE_FAILURE = "UPDATE_SERVICE_TITLE_FAILURE"
export const UPDATE_SERVICE_TITLE_SUCCESS = "UPDATE_SERVICE_TITLE_SUCCESS"

export const ADD_SERVICE_ATTRIBUTE_REQUEST = "ADD_SERVICE_ATTRIBUTE_REQUEST"
export const ADD_SERVICE_ATTRIBUTE_FAILURE = "ADD_SERVICE_ATTRIBUTE_FAILURE"
export const ADD_SERVICE_ATTRIBUTE_SUCCESS = "ADD_SERVICE_ATTRIBUTE_SUCCESS"

export const UPDATE_SERVICE_ATTRIBUTE_REQUEST = "UPDATE_SERVICE_ATTRIBUTE_REQUEST"
export const UPDATE_SERVICE_ATTRIBUTE_FAILURE = "UPDATE_SERVICE_ATTRIBUTE_FAILURE"
export const UPDATE_SERVICE_ATTRIBUTE_SUCCESS = "UPDATE_SERVICE_ATTRIBUTE_SUCCESS"

export const VIEW_ALL_PACKAGE_NAMES_REQUEST = "VIEW_ALL_PACKAGE_NAMES_REQUEST"
export const VIEW_ALL_PACKAGE_NAMES_SUCCESS = "VIEW_ALL_PACKAGE_NAMES_SUCCESS"
export const VIEW_ALL_PACKAGE_NAMES_FAILURE = "VIEW_ALL_PACKAGE_NAMES_FAILURE"

export const VIEW_ALL_SERVICES_NAMES_AND_UUID_REQUEST = "VIEW_ALL_SERVICES_NAMES_AND_UUID__REQUEST"
export const VIEW_ALL_SERVICES_NAMES_AND_UUID_SUCCESS = "VIEW_ALL_SERVICES_NAMES_AND_UUID__SUCCESS"
export const VIEW_ALL_SERVICES_NAMES_AND_UUID_FAILURE = "VIEW_ALL_SERVICES_NAMES_AND_UUID__FAILURE"

export const VIEW_ALL_SERVICES_ATTRIBUTES_REQUEST = "VIEW_ALL_SERVICES_ATTRIBUTES_REQUEST"
export const VIEW_ALL_SERVICES_ATTRIBUTES_SUCCESS = "VIEW_ALL_SERVICES_ATTRIBUTES_SUCCESS"
export const VIEW_ALL_SERVICES_ATTRIBUTES_FAILURE = "VIEW_ALL_SERVICES_ATTRIBUTES_FAILURE"

export const ADD_PROGRAM_TEMPLATE_REQUEST = "ADD_PROGRAM_TEMPLATE_REQUEST"
export const ADD_PROGRAM_TEMPLATE_FAILURE = "ADD_PROGRAM_TEMPLATE_FAILURE"
export const ADD_PROGRAM_TEMPLATE_SUCCESS = "ADD_PROGRAM_TEMPLATE_SUCCESS"

export const VIEW_ALL_PROGRAM_TEMPLATE_NAMES_REQUEST = "VIEW_ALL_PROGRAM_TEMPLATE_NAMES_REQUEST"
export const VIEW_ALL_PROGRAM_TEMPLATE_NAMES_SUCCESS = "VIEW_ALL_PROGRAM_TEMPLATE_NAMES_SUCCESS"
export const VIEW_ALL_PROGRAM_TEMPLATE_NAMES_FAILURE = "VIEW_ALL_PROGRAM_TEMPLATE_NAMES_FAILURE"


export const VIEW_ALL_TEMPLATE_DATA_REQUEST = "VIEW_ALL_TEMPLATE_DATA_REQUEST"
export const VIEW_ALL_TEMPLATE_DATA_SUCCESS = "VIEW_ALL_TEMPLATE_DATA_SUCCESS"
export const VIEW_ALL_TEMPLATE_DATA_FAILURE = "VIEW_ALL_TEMPLATE_DATA_FAILURE"


export const VIEW_MY_PROGRAM_DATA_REQUEST = "VIEW_MY_PROGRAM_DATA_REQUEST"
export const VIEW_MY_PROGRAM_DATA_SUCCESS = "VIEW_MY_PROGRAM_DATA_SUCCESS"
export const VIEW_MY_PROGRAM_DATA_FAILURE = "VIEW_MY_PROGRAM_DATA_FAILURE"


export const ASSIGN_PROGRAM_REQUEST = "ASSIGN_PROGRAM_REQUEST"
export const ASSIGN_PROGRAM_FAILURE = "ASSIGN_PROGRAM_FAILURE"
export const ASSIGN_PROGRAM_SUCCESS = "ASSIGN_PROGRAM_SUCCESS"

export const VIEW_ALL_CITY_REQUEST = "VIEW_ALL_CITY_REQUEST"
export const VIEW_ALL_CITY_SUCCESS = "VIEW_ALL_CITY_SUCCESS"
export const VIEW_ALL_CITY_FAILURE = "VIEW_ALL_CITY_FAILURE"

export const GET_UPDATE_CORPORATE_REQUEST = "GET_UPDATE_CORPORATE_REQUEST"
export const GET_UPDATE_CORPORATE_SUCCESS = "GET_UPDATE_CORPORATE_SUCCESS"
export const GET_UPDATE_CORPORATE_FAILURE = "GET_UPDATE_CORPORATE_FAILURE"

export const GET_UPDATE_HOSPITAL_REQUEST = "GET_UPDATE_HOSPITAL_REQUEST"
export const GET_UPDATE_HOSPITAL_SUCCESS = "GET_UPDATE_HOSPITAL_SUCCESS"
export const GET_UPDATE_HOSPITAL_FAILURE = "GET_UPDATE_HOSPITAL_FAILURE"

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST"
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE"

export const GET_UPDATE_INSURER_REQUEST = "GET_UPDATE_INSURER_REQUEST"
export const GET_UPDATE_INSURER_SUCCESS = "GET_UPDATE_INSURER_SUCCESS"
export const GET_UPDATE_INSURER_FAILURE = "GET_UPDATE_INSURER_FAILURE"

export const CHECK_EMAIL_AGENT_REQUEST = "CHECK_EMAIL_AGENT_REQUEST"
export const CHECK_EMAIL_AGENT_SUCCESS = "CHECK_EMAIL_AGENT_SUCCESS"
export const CHECK_EMAIL_AGENT_FAILURE = "CHECK_EMAIL_AGENT_FAILURE"

export const ALL_CITY_APPOINTMENT_REQUEST = "ALL_CITY_APPOINTMENT_REQUEST"
export const ALL_CITY_APPOINTMENT_SUCCESS = "ALL_CITY_APPOINTMENT_SUCCESS"
export const ALL_CITY_APPOINTMENT_FAILURE = "ALL_CITY_APPOINTMENT_FAILURE"

export const SEARCH_DOCTOR_REQUEST = "SEARCH_DOCTOR_REQUEST"
export const SEARCH_DOCTOR_SUCCESS = "SEARCH_DOCTOR_SUCCESS"
export const SEARCH_DOCTOR_FAILURE = "SEARCH_DOCTOR_FAILURE"

export const VIEW_PROGRAM_TEMPLATE_REQUEST = "VIEW_PROGRAM_TEMPLATE_REQUEST"
export const VIEW_PROGRAM_TEMPLATE_SUCCESS = "VIEW_PROGRAM_TEMPLATE_SUCCESS"
export const VIEW_PROGRAM_TEMPLATE_FAILURE = "VIEW_PROGRAM_TEMPLATE_FAILURE"

export const GET_PROGRAM_TEMPLATE_REQUEST = "GET_PROGRAM_TEMPLATE_REQUEST"
export const GET_PROGRAM_TEMPLATE_SUCCESS = "GET_PROGRAM_TEMPLATE_SUCCESS"
export const GET_PROGRAM_TEMPLATE_FAILURE = "GET_PROGRAM_TEMPLATE_FAILURE"

export const VIEW_ALL_SERVICE_FOR_CORPORATE_REQUEST = "VIEW_ALL_SERVICE_FOR_CORPORATE_REQUEST"
export const VIEW_ALL_SERVICE_FOR_CORPORATE_SUCCESS = "VIEW_ALL_SERVICE_FOR_CORPORATE_SUCCESS"
export const VIEW_ALL_SERVICE_FOR_CORPORATE_FAILURE = "VIEW_ALL_SERVICE_FOR_CORPORATE_FAILURE"

export const ASSIGN_PROGRAM_TO_EMPLOYEE_REQUEST = "ASSIGN_PROGRAM_TO_EMPLOYEE_REQUEST"
export const ASSIGN_PROGRAM_TO_EMPLOYEE_SUCCESS = "ASSIGN_PROGRAM_TO_EMPLOYEE_SUCCESS"
export const ASSIGN_PROGRAM_TO_EMPLOYEE_FAILURE = "ASSIGN_PROGRAM_TO_EMPLOYEE_FAILURE"

export const GET_EMPLOYEE_PROGRAM_REQUEST = "GET_EMPLOYEE_PROGRAM_REQUEST"
export const GET_EMPLOYEE_PROGRAM_SUCCESS = "GET_EMPLOYEE_PROGRAM_SUCCESS"
export const GET_EMPLOYEE_PROGRAM_FAILURE = "GET_EMPLOYEE_PROGRAM_FAILURE"

export const VIEW_ALL_EMPLOYEE_PROGRAM_REQUEST = "VIEW_ALL_EMPLOYEE_PROGRAM_REQUEST"
export const VIEW_ALL_EMPLOYEE_PROGRAM_SUCCESS = "VIEW_ALL_EMPLOYEE_PROGRAM_SUCCESS"
export const VIEW_ALL_EMPLOYEE_PROGRAM_FAILURE = "VIEW_ALL_EMPLOYEE_PROGRAM_FAILURE"

export const VALIDATE_OTP_LOGIN_REQUEST = "VALIDATE_OTP_LOGIN_REQUEST"
export const VALIDATE_OTP_LOGIN_CLEAR_REQUEST = "VALIDATE_OTP_LOGIN_CLEAR_REQUEST"
export const VALIDATE_OTP_LOGIN_SUCCESS = "VALIDATE_OTP_LOGIN_SUCCESS"
export const VALIDATE_OTP_LOGIN_FAILURE = "VALIDATE_OTP_LOGIN_FAILURE"

export const FILE_TEMPLATE_REQUEST = "FILE_TEMPLATE_REQUEST"
export const FILE_TEMPLATE_SUCCESS = "FILE_TEMPLATE_SUCCESS"
export const FILE_TEMPLATE_FAILURE = "FILE_TEMPLATE_FAILURE"

export const ADD_LAB_REQUEST = "ADD_LAB_REQUEST"
export const ADD_LAB_SUCCESS = "ADD_LAB_SUCCESS"
export const ADD_LAB_FAILURE = "ADD_LAB_FAILURE"

export const ADD_TEST_REQUEST = "ADD_TEST_REQUEST"
export const ADD_TEST_SUCCESS = "ADD_TEST_SUCCESS"
export const ADD_TEST_FAILURE = "ADD_TEST_FAILURE"

export const UPDATE_TEST_REQUEST = "UPDATE_TEST_REQUEST"
export const UPDATE_TEST_SUCCESS = "UPDATE_TEST_SUCCESS"
export const UPDATE_TEST_FAILURE = "UPDATE_TEST_FAILURE"



export const VIEW_ALL_TESTS_NAME_REQUEST = "VIEW_ALL_TESTS_NAME_REQUEST"
export const VIEW_ALL_TESTS_NAME_SUCCESS = "VIEW_ALL_TESTS_NAME_SUCCESS"
export const VIEW_ALL_TESTS_NAME_FAILURE = "VIEW_ALL_TESTS_NAME_FAILURE"


export const VIEW_ALL_LAB_REQUEST = "VIEW_ALL_LAB_REQUEST"
export const VIEW_ALL_LAB_SUCCESS = "VIEW_ALL_LAB_SUCCESS"
export const VIEW_ALL_LAB_FAILURE = "VIEW_ALL_LAB_FAILURE"

export const VIEW_LAB_REQUEST = "VIEW_LAB_REQUEST"
export const VIEW_LAB_SUCCESS = "VIEW_LAB_SUCCESS"
export const VIEW_LAB_FAILURE = "VIEW_LAB_FAILURE"

export const VIEW_PACKAGES_REQUEST = "VIEW_PACKAGES_REQUEST"
export const VIEW_PACKAGES_SUCCESS = "VIEW_PACKAGES_SUCCESS"
export const VIEW_PACKAGES_FAILURE = "VIEW_PACKAGES_FAILURE"


export const VIEW_BOOKING_REQUEST = "VIEW_BOOKING_REQUEST"
export const VIEW_BOOKING_SUCCESS = "VIEW_BOOKING_SUCCESS"
export const VIEW_BOOKING_FAILURE = "VIEW_BOOKING_FAILURE"

export const CONFIRM_BOOKING_REQUEST = "CONFIRM_BOOKING_REQUEST"
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS"
export const CONFIRM_BOOKING_FAILURE = "CONFIRM_BOOKING_FAILURE"

export const RESCHEDULE_BOOKING_REQUEST = "RESCHEDULE_BOOKING_REQUEST"
export const RESCHEDULE_BOOKING_SUCCESS = "RESCHEDULE_BOOKING_SUCCESS"
export const RESCHEDULE_BOOKING_FAILURE = "RESCHEDULE_BOOKING_FAILURE"

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST"
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS"
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE"

export const VIEW_IN_PACKAGES_REQUEST = "VIEW_IN_PACKAGES_REQUEST"
export const VIEW_IN_PACKAGES_SUCCESS = "VIEW_IN_PACKAGES_SUCCESS"
export const VIEW_IN_PACKAGES_FAILURE = "VIEW_IN_PACKAGES_FAILURE"

export const VIEW_ALL_BOOKING_SUCCESS = "VIEW_ALL_BOOKING_SUCCESS"
export const VIEW_ALL_BOOKING_REQUEST = "VIEW_ALL_BOOKING_REQUEST"
export const VIEW_ALL_BOOKING_FAILURE = "VIEW_ALL_BOOKING_FAILURE"

export const DELETE_LAB_REQUEST = "DELETE_LAB_REQUEST"
export const DELETE_LAB_SUCCESS = "DELETE_LAB_SUCCESS"
export const DELETE_LAB_FAILURE = "DELETE_LAB_FAILURE"

export const UPDATE_LAB_REQUEST = "UPDATE_LAB_REQUEST"
export const UPDATE_LAB_SUCCESS = "UPDATE_LAB_SUCCESS"
export const UPDATE_LAB_FAILURE = "UPDATE_LAB_FAILURE"

export const UPLOAD_REPORT_REQUEST = "UPLOAD_REPORT_REQUEST"
export const UPLOAD_REPORT_SUCCESS = "UPLOAD_REPORT_SUCCESS"
export const UPLOAD_REPORT_FAILURE = "UPLOAD_REPORT_FAILURE"

export const MAKE_PACKAGE_REQUEST = "MAKE_PACKAGE_REQUEST"
export const MAKE_PACKAGE_SUCCESS = "MAKE_PACKAGE_SUCCESS"
export const MAKE_PACKAGE_FAILURE = "MAKE_PACKAGE_FAILURE"

export const VIEW_ALL_TEST_REQUEST = "VIEW_ALL_TEST_REQUEST"
export const VIEW_ALL_TEST_SUCCESS = "VIEW_ALL_TEST_SUCCESS"
export const VIEW_ALL_TEST_FAILURE = "VIEW_ALL_TEST_FAILURE"

export const VIEW_IN_LAB_REQUEST = "VIEW_IN_LAB_REQUEST"
export const VIEW_IN_LAB_SUCCESS = "VIEW_IN_LAB_SUCCESS"
export const VIEW_IN_LAB_FAILURE = "VIEW_IN_LAB_FAILURE"

export const VIEW_TEST_REQUEST = "VIEW_TEST_REQUEST"
export const VIEW_TEST_SUCCESS = "VIEW_TEST_SUCCESS"
export const VIEW_TEST_FAILURE = "VIEW_TEST_FAILURE"

export const FINISH_BOOKING_REQUEST = "FINISH_BOOKING_REQUEST"
export const FINISH_BOOKING_SUCCESS = "FINISH_BOOKING_SUCCESS"
export const FINISH_BOOKING_FAILURE = "FINISH_BOOKING_FAILURE"

export const COMPLETE_BOOKING_REQUEST = "COMPLETE_BOOKING_REQUEST"
export const COMPLETE_BOOKING_SUCCESS = "COMPLETE_BOOKING_SUCCESS"
export const COMPLETE_BOOKING_FAILURE = "COMPLETE_BOOKING_FAILURE"

export const UPDATE_PACKAGE_REQUEST = "UPDATE_PACKAGE_REQUEST"
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS"
export const UPDATE_PACKAGE_FAILURE = "UPDATE_PACKAGE_FAILURE"

export const DOWNLOAD_ATTACHMENT_REQUEST = "DOWNLOAD_ATTACHMENT_REQUEST"
export const DOWNLOAD_ATTACHMENT_SUCCESS = "DOWNLOAD_ATTACHMENT_SUCCESS"
export const DOWNLOAD_ATTACHMENT_FAILURE = "DOWNLOAD_ATTACHMENT_FAILURE"

export const DELETE_ATTACHMENT_REQUEST = "DELETE_ATTACHMENT_REQUEST"
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS"
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE"

export const UPLOAD_REPORT_APPOINTMENTS_REQUEST = "UPLOAD_REPORT_APPOINTMENTS_REQUEST"
export const UPLOAD_REPORT_APPOINTMENTS_SUCCESS = "UPLOAD_REPORT_APPOINTMENTS_SUCCESS"
export const UPLOAD_REPORT_APPOINTMENTS_FAILURE = "UPLOAD_REPORT_APPOINTMENTS_FAILURE"

export const FINISH_APPOINTMENT_REQUEST = "FINISH_APPOINTMENT_REQUEST"
export const FINISH_APPOINTMENT_SUCCESS = "FINISH_APPOINTMENT_SUCCESS"
export const FINISH_APPOINTMENT_FAILURE = "FINISH_APPOINTMENT_FAILURE"

export const DOWNLOAD_PRESCRIPTION_REQUEST = "DOWNLOAD_PRESCRIPTION_REQUEST"
export const DOWNLOAD_PRESCRIPTION_SUCCESS = "DOWNLOAD_PRESCRIPTION_SUCCESS"
export const DOWNLOAD_PRESCRIPTION_FAILURE = "DOWNLOAD_PRESCRIPTION_FAILURE"

export const LIST_OF_CONSULTATIONS_REQUEST = "LIST_OF_CONSULTATIONS_REQUEST"
export const LIST_OF_CONSULTATIONS_SUCCESS = "LIST_OF_CONSULTATIONS_SUCCESS"
export const LIST_OF_CONSULTATIONS_FAILURE = "LIST_OF_CONSULTATIONS_FAILURE"

export const CONSULTATION_REQUESTS_REQUEST = "CONSULTATION_REQUESTS_REQUEST"
export const CONSULTATION_REQUESTS_SUCCESS = "CONSULTATION_REQUESTS_SUCCESS"
export const CONSULTATION_REQUESTS_FAILURE = "CONSULTATION_REQUESTS_FAILURE"

export const SUBMIT_PRESCRIPTION_REQUEST = "SUBMIT_PRESCRIPTION_REQUEST"
export const SUBMIT_PRESCRIPTION_SUCCESS = "SUBMIT_PRESCRIPTION_SUCCESS"
export const SUBMIT_PRESCRIPTION_FAILURE = "SUBMIT_PRESCRIPTION_FAILURE"

export const DASHBOARD_DATA_REQUEST = "DASHBOARD_DATA_REQUEST"
export const DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS"
export const DASHBOARD_DATA_FAILURE = "DASHBOARD_DATA_FAILURE"

export const ACCEPT_OR_REJECT_REQUEST_REQUEST = "ACCEPT_OR_REJECT_REQUEST_REQUEST"
export const ACCEPT_OR_REJECT_REQUEST_SUCCESS = "ACCEPT_OR_REJECT_REQUEST_SUCCESS"
export const ACCEPT_OR_REJECT_REQUEST_FAILURE = "ACCEPT_OR_REJECT_REQUEST_FAILURE"

export const ASSIGN_LAB_REQUEST = "ASSIGN_LAB_REQUEST"
export const ASSIGN_LAB_SUCCESS = "ASSIGN_LAB_SUCCESS"
export const ASSIGN_LAB_FAILURE = "ASSIGN_LAB_FAILURE"

export const VIEW_ALL_LABS_NAMES_REQUEST = "VIEW_ALL_LABS_NAMES_REQUEST"
export const VIEW_ALL_LABS_NAMES_SUCCESS = "VIEW_ALL_LABS_NAMES_SUCCESS"
export const VIEW_ALL_LABS_NAMES_FAILURE = "VIEW_ALL_LABS_NAMES_FAILURE"

export const VIEW_ALL_PAYMENT_REQUEST = "VIEW_ALL_PAYMENT_REQUEST"
export const VIEW_ALL_PAYMENT_SUCCESS = "VIEW_ALL_PAYMENT_SUCCESS"
export const VIEW_ALL_PAYMENT_FAILURE = "VIEW_ALL_PAYMENT_FAILURE"

export const CHAT_STATUS_REQUEST = "CHAT_STATUS_REQUEST"
export const CHAT_STATUS_SUCCESS = "CHAT_STATUS_SUCCESS"
export const CHAT_STATUS_FAILURE = "CHAT_STATUS_FAILURE"

export const VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_REQUEST = "VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_REQUEST"
export const VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_SUCCESS = "VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_SUCCESS"
export const VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_FAILURE = "VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_FAILURE"

export const CREATE_LOT_REQUEST = "CREATE_LOT_REQUEST"
export const CREATE_LOT_SUCCESS = "CREATE_LOT_SUCCESS"
export const CREATE_LOT_FAILURE = "CREATE_LOT_FAILURE"


export const GET_ALL_LOTS_REQUEST = "GET_ALL_LOTS_REQUEST"
export const GET_ALL_LOTS_SUCCESS = "GET_ALL_LOTS_SUCCESS"
export const GET_ALL_LOTS_FAILURE = "GET_ALL_LOTS_FAILURE"

export const GET_LOT_BY_ID_REQUEST = "GET_LOT_BY_ID_REQUEST"
export const GET_LOT_BY_ID_SUCCESS = "GET_LOT_BY_ID_SUCCESS"
export const GET_LOT_BY_ID_FAILURE = "GET_LOT_BY_ID_FAILURE"

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE"

export const GET_RELATION_REQUEST = "GET_RELATION_REQUEST"
export const GET_RELATION_SUCCESS = "GET_RELATION_SUCCESS"
export const GET_RELATION_FAILURE = "GET_RELATION_FAILURE"

export const GET_ALL_LOTS_IDS_REQUEST = "GET_ALL_LOTS_IDS_REQUEST"
export const GET_ALL_LOTS_IDS_SUCCESS = "GET_ALL_LOTS_IDS_SUCCESS"
export const GET_ALL_LOTS_IDS_FAILURE = "GET_ALL_LOTS_IDS_FAILURE"

export const ADD_BOOKING_REQUEST = "ADD_BOOKING_REQUEST"
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
export const ADD_BOOKING_FAILURE = "ADD_BOOKING_FAILURE"

export const GET_MY_TESTS_AND_PACKAGE_REQUEST = "GET_MY_TESTS_AND_PACKAGE_REQUEST"
export const GET_MY_TESTS_AND_PACKAGE_SUCCESS = "GET_MY_TESTS_AND_PACKAGE_SUCCESS"
export const GET_MY_TESTS_AND_PACKAGE_FAILURE = "GET_MY_TESTS_AND_PACKAGE_FAILURE"

export const NETWORK_HOSPITAL_REQUEST = "NETWORK_HOSPITAL_REQUEST"
export const NETWORK_HOSPITAL_SUCCESS = "NETWORK_HOSPITAL_SUCCESS"
export const NETWORK_HOSPITAL_FAILURE = "NETWORK_HOSPITAL_FAILURE"

export const NETWORK_VIEW_HOSPITAL_REQUEST = "NETWORK_VIEW_HOSPITAL_REQUEST"
export const NETWORK_VIEW_HOSPITAL_SUCCESS = "NETWORK_VIEW_HOSPITAL_SUCCESS"
export const NETWORK_VIEW_HOSPITAL_FAILURE = "NETWORK_VIEW_HOSPITAL_FAILURE"

export const APPROVE_HOSPITAL_REQUEST = "APPROVE_HOSPITAL_REQUEST"
export const APPROVE_HOSPITAL_SUCCESS = "APPROVE_HOSPITAL_SUCCESS"
export const APPROVE_HOSPITAL_FAILURE = "APPROVE_HOSPITAL_FAILURE"

export const NETWORK_CLINIC_REQUEST = "NETWORK_CLINIC_REQUEST"
export const NETWORK_CLINIC_SUCCESS = "NETWORK_CLINIC_SUCCESS"
export const NETWORK_CLINIC_FAILURE = "NETWORK_CLINIC_FAILURE"

export const NETWORK_VIEW_CLINIC_REQUEST = "NETWORK_VIEW_CLINIC_REQUEST"
export const NETWORK_VIEW_CLINIC_SUCCESS = "NETWORK_VIEW_CLINIC_SUCCESS"
export const NETWORK_VIEW_CLINIC_FAILURE = "NETWORK_VIEW_CLINIC_FAILURE"

export const APPROVE_CLINIC_REQUEST = "APPROVE_CLINIC_REQUEST"
export const APPROVE_CLINIC_SUCCESS = "APPROVE_CLINIC_SUCCESS"
export const APPROVE_CLINIC_FAILURE = "APPROVE_CLINIC_FAILURE"

export const NETWORK_DIAGNOSTIC_REQUEST = "NETWORK_DIAGNOSTIC_REQUEST"
export const NETWORK_DIAGNOSTIC_SUCCESS = "NETWORK_DIAGNOSTIC_SUCCESS"
export const NETWORK_DIAGNOSTIC_FAILURE = "NETWORK_DIAGNOSTIC_FAILURE"

export const NETWORK_VIEW_DIAGNOSTIC_REQUEST = "NETWORK_VIEW_DIAGNOSTIC_REQUEST"
export const NETWORK_VIEW_DIAGNOSTIC_SUCCESS = "NETWORK_VIEW_DIAGNOSTIC_SUCCESS"
export const NETWORK_VIEW_DIAGNOSTIC_FAILURE = "NETWORK_VIEW_DIAGNOSTIC_FAILURE"

export const APPROVE_DIAGNOSTIC_REQUEST = "APPROVE_DIAGNOSTIC_REQUEST"
export const APPROVE_DIAGNOSTIC_SUCCESS = "APPROVE_DIAGNOSTIC_SUCCESS"
export const APPROVE_DIAGNOSTIC_FAILURE = "APPROVE_DIAGNOSTIC_FAILURE"


export const UPDATE_PACKAGE_FEATURE_REQUEST = "UPDATE_PACKAGE_FEATURE_REQUEST"
export const UPDATE_PACKAGE_FEATURE_SUCCESS = "UPDATE_PACKAGE_FEATURE_SUCCESS"
export const UPDATE_PACKAGE_FEATURE_FAILURE = "UPDATE_PACKAGE_FEATURE_FAILURE"


export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST"
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS"
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE"


export const RELATIONSHIP_REQUEST = "RELATIONSHIP_REQUEST"
export const RELATIONSHIP_SUCCESS = "RELATIONSHIP_SUCCESS"
export const RELATIONSHIP_FAILURE = "RELATIONSHIP_FAILURE"


export const PROGRAM_AND_PLAN_REQUEST = "PROGRAM_AND_PLAN_REQUEST"
export const PROGRAM_AND_PLAN_SUCCESS = "PROGRAM_AND_PLAN_SUCCESS"
export const PROGRAM_AND_PLAN_FAILURE = "PROGRAM_AND_PLAN_FAILURE"
export const CORPORATE_VIEW_ASSIGN_PROGRAM_REQUEST = "CORPORATE_VIEW_ASSIGN_PROGRAM_REQUEST"
export const CORPORATE_VIEW_ASSIGN_PROGRAM_SUCCESS = "CORPORATE_VIEW_ASSIGN_PROGRAM_SUCCESS"
export const CORPORATE_VIEW_ASSIGN_PROGRAM_FAILURE = "CORPORATE_VIEW_ASSIGN_PROGRAM_FAILURE"

export const CORPORATE_GET_ASSIGN_PROGRAM_REQUEST = "CORPORATE_GET_ASSIGN_PROGRAM_REQUEST"
export const CORPORATE_GET_ASSIGN_PROGRAM_SUCCESS = " CORPORATE_GET_ASSIGN_PROGRAM_SUCCESS"
export const CORPORATE_GET_ASSIGN_PROGRAM_FAILURE = "CORPORATE_GET_ASSIGN_PROGRAM_FAILURE"

export const CHECK_NUMBER_REQUEST = "CHECK_NUMBER_REQUEST"
export const CHECK_NUMBER_SUCCESS = "CHECK_NUMBER_SUCCESS"
export const CHECK_NUMBER_FAILURE = "CHECK_NUMBER_FAILURE"

export const VIEW_ALL_PROGRAM_OF_INSURER_REQUEST = "VIEW_ALL_PROGRAM_OF_INSURER_REQUEST"
export const VIEW_ALL_PROGRAM_OF_INSURER_SUCCESS = "VIEW_ALL_PROGRAM_OF_INSURER_SUCCESS"
export const VIEW_ALL_PROGRAM_OF_INSURER_FAILURE = "VIEW_ALL_PROGRAM_OF_INSURER_FAILURE"

export const ASSIGN_PROGRAM_INSURER_REQUEST = "ASSIGN_PROGRAM_INSURER_REQUEST"
export const ASSIGN_PROGRAM_INSURER_SUCCESS = "ASSIGN_PROGRAM_INSURER_SUCCESS"
export const ASSIGN_PROGRAM_INSURER_FAILURE = "ASSIGN_PROGRAM_INSURER_FAILURE"

export const ADD_PLAN_REQUEST = "ADD_PLAN_REQUEST"
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS"
export const ADD_PLAN_FAILURE = "ADD_PLAN_FAILURE"

export const DELETE_PROGRAM_TEMPLATE_REQUEST = "DELETE_PROGRAM_TEMPLATE_REQUEST"
export const DELETE_PROGRAM_TEMPLATE_SUCCESS = "DELETE_PROGRAM_TEMPLATE_SUCCESS"
export const DELETE_PROGRAM_TEMPLATE_FAILURE = "DELETE_PROGRAM_TEMPLATE_FAILURE"

export const GET_INSURER_ASSIGNED_PROGRAMS_REQUEST = "GET_INSURER_ASSIGNED_PROGRAMS_REQUEST"
export const GET_INSURER_ASSIGNED_PROGRAMS_SUCCESS = "GET_INSURER_ASSIGNED_PROGRAMS_SUCCESS"
export const GET_INSURER_ASSIGNED_PROGRAMS_FAILURE = "GET_INSURER_ASSIGNED_PROGRAMS_FAILURE"

export const ASSIGN_PROGRAM_TO_INSURER_REQUEST = "ASSIGN_PROGRAM_TO_INSURER_REQUEST"
export const ASSIGN_PROGRAM_TO_INSURER_SUCCESS = "ASSIGN_PROGRAM_TO_INSURER_SUCCESS"
export const ASSIGN_PROGRAM_TO_INSURER_FAILURE = "ASSIGN_PROGRAM_TO_INSURER_FAILURE"

export const GET_PROGRAM_INSURER_REQUEST = "GET_PROGRAM_INSURER_REQUEST"
export const GET_PROGRAM_INSURER_SUCCESS = "GET_PROGRAM_INSURER_SUCCESS"
export const GET_PROGRAM_INSURER_FAILURE = "GET_PROGRAM_INSURER_FAILURE"


export const VIEW_INSURER_PROGRAM_REQUEST = "VIEW_INSURER_PROGRAM_REQUEST"
export const VIEW_INSURER_PROGRAM_SUCCESS = "VIEW_INSURER_PROGRAM_SUCCESS"
export const VIEW_INSURER_PROGRAM_FAILURE = "VIEW_INSURER_PROGRAM_FAILURE"

export const GET_ALL_COUPON_REQUEST = "GET_ALL_COUPON_REQUEST"
export const GET_ALL_COUPON_SUCCESS = "GET_ALL_COUPON_SUCCESS"
export const GET_ALL_COUPON_FAILURE = "GET_ALL_COUPON_FAILURE"

export const EDIT_COUPON_REQUEST = "EDIT_COUPON_REQUEST"
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS"
export const EDIT_COUPON_FAILURE = "EDIT_COUPON_FAILURE"

export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST"
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS"
export const DELETE_COUPON_FAILURE = "DELETE_COUPON_FAILURE"

export const ADD_COUPON_REQUEST = "ADD_COUPON_REQUEST"
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS"
export const ADD_COUPON_FAILURE = "ADD_COUPON_FAILURE"

export const GET_COUPON_DETAILS_REQUEST = "GET_COUPON_DETAILS_REQUEST"
export const GET_COUPON_DETAILS_SUCCESS = "GET_COUPON_DETAILS_SUCCESS"
export const GET_COUPON_DETAILS_FAILURE = "GET_COUPON_DETAILS_FAILURE"

export const ADD_DISCOUNT_GROUP_REQUEST = "ADD_DISCOUNT_GROUP_REQUEST"
export const ADD_DISCOUNT_GROUP_SUCCESS = "ADD_DISCOUNT_GROUP_SUCCESS"
export const ADD_DISCOUNT_GROUP_FAILURE = "ADD_DISCOUNT_GROUP_FAILURE"

export const ADD_DISCOUNT_INDIVIDUAL_REQUEST = "ADD_DISCOUNT_INDIVIDUAL_REQUEST"
export const ADD_DISCOUNT_INDIVIDUAL_SUCCESS = "ADD_DISCOUNT_INDIVIDUAL_SUCCESS"
export const ADD_DISCOUNT_INDIVIDUAL_FAILURE = "ADD_DISCOUNT_INDIVIDUAL_FAILURE"

export const EDIT_DISCOUNT_REQUEST = "EDIT_DISCOUNT_REQUEST"
export const EDIT_DISCOUNT_SUCCESS = "EDIT_DISCOUNT_SUCCESS"
export const EDIT_DISCOUNT_FAILURE = "EDIT_DISCOUNT_FAILURE"

export const GET_ALL_DISCOUNT_REQUEST = "GET_ALL_DISCOUNT_REQUEST"
export const GET_ALL_DISCOUNT_SUCCESS = "GET_ALL_DISCOUNT_SUCCESS"
export const GET_ALL_DISCOUNT_FAILURE = "GET_ALL_DISCOUNT_FAILURE"

export const VIEW_DISCOUNT_REQUEST = "VIEW_DISCOUNT_REQUEST"
export const VIEW_DISCOUNT_SUCCESS = "VIEW_DISCOUNT_SUCCESS"
export const VIEW_DISCOUNT_FAILURE = "VIEW_DISCOUNT_FAILURE"

export const DELETE_DISCOUNT_REQUEST = "DELETE_DISCOUNT_REQUEST"
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS"
export const DELETE_DISCOUNT_FAILURE = "DELETE_DISCOUNT_FAILURE"


export const PLAN_TYPE_DROPDOWN_REQUEST = "PLAN_TYPE_DROPDOWN_REQUEST"
export const PLAN_TYPE_DROPDOWN_SUCCESS = "PLAN_TYPE_DROPDOWN_SUCCESS"
export const PLAN_TYPE_DROPDOWN_FAILURE = "PLAN_TYPE_DROPDOWN_FAILURE"


export const NETWORK_PROFESSIONAL_SERVICE_REQUEST = "NETWORK_PROFESSIONAL_SERVICE_REQUEST"
export const NETWORK_PROFESSIONAL_SERVICE_SUCCESS = "NETWORK_PROFESSIONAL_SERVICE_SUCCESS"
export const NETWORK_PROFESSIONAL_SERVICE_FAILURE = "NETWORK_PROFESSIONAL_SERVICE_FAILURE"


export const NETWORK_VIEW_PROFESSIONAL_SERVICES_REQUEST = "NETWORK_VIEW_PROFESSIONAL_SERVICES_REQUEST"
export const NETWORK_VIEW_PROFESSIONAL_SERVICES_SUCCESS = "NETWORK_VIEW_PROFESSIONAL_SERVICES_SUCCESS"
export const NETWORK_VIEW_PROFESSIONAL_SERVICES_FAILURE = "NETWORK_VIEW_PROFESSIONAL_SERVICES_FAILURE"


export const APPROVE_PROFESSIONAL_SERVICES_REQUEST = "APPROVE_PROFESSIONAL_SERVICES_REQUEST"
export const APPROVE_PROFESSIONAL_SERVICES_SUCCESS = "APPROVE_PROFESSIONAL_SERVICES_SUCCESS"
export const APPROVE_PROFESSIONAL_SERVICES_FAILURE = "APPROVE_PROFESSIONAL_SERVICES_FAILURE"


export const VIEW_ORDER_REQUEST = "VIEW_ORDER_REQUEST"
export const VIEW_ORDER_SUCCESS = "VIEW_ORDER_SUCCESS"
export const VIEW_ORDER_FAILURE = "VIEW_ORDER_FAILURE"

export const VIEW_ORDER_DROPDOWN_REQUEST = "VIEW_ORDER_DROPDOWN_REQUEST"
export const VIEW_ORDER_DROPDOWN_SUCCESS = "VIEW_ORDER_DROPDOWN_SUCCESS"
export const VIEW_ORDER_DROPDOWN_FAILURE = "VIEW_ORDER_DROPDOWN_FAILURE"

export const CHANGE_ORDER_STATUS_REQUEST = "CHANGE_ORDER_STATUS_REQUEST"
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS"
export const CHANGE_ORDER_STATUS_FAILURE = "CHANGE_ORDER_STATUS_FAILURE"

export const VIEW_ALL_USERS_REQUEST = "VIEW_ALL_USERS_REQUEST"
export const VIEW_ALL_USERS_SUCCESS = "VIEW_ALL_USERS_SUCCESS"
export const VIEW_ALL_USERS_FAILURE = "VIEW_ALL_USERS_FAILURE"

export const VIEW_ALL_USERS_DROPDOWN_REQUEST = "VIEW_ALL_USERS_DROPDOWN_REQUEST"
export const VIEW_ALL_USERS_DROPDOWN_SUCCESS = "VIEW_ALL_USERS_DROPDOWN_SUCCESS"
export const VIEW_ALL_USERS_DROPDOWN_FAILURE = "VIEW_ALL_USERS_DROPDOWN_FAILURE"

export const VIEW_ALL_PLAN_REQUEST = "VIEW_ALL_PLAN_REQUEST"
export const VIEW_ALL_PLAN_SUCCESS = "VIEW_ALL_PLAN_SUCCESS"
export const VIEW_ALL_PLAN_FAILURE = "VIEW_ALL_PLAN_FAILURE"

export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST"
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS"
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE"

export const GET_PLAN_DETAIL_REQUEST = "GET_PLAN_DETAIL_REQUEST"
export const GET_PLAN_DETAIL_SUCCESS = "GET_PLAN_DETAIL_SUCCESS"
export const GET_PLAN_DETAIL_FAILURE = "GET_PLAN_DETAIL_FAILURE"

export const GET_DOSAGE_TYPE_REQUEST = "GET_DOSAGE_TYPE_REQUEST"
export const GET_DOSAGE_TYPE_SUCCESS = "GET_DOSAGE_TYPE_SUCCESS"
export const GET_DOSAGE_TYPE_FAILURE = "GET_DOSAGE_TYPE_FAILURE"

export const GET_MED_TYPE_REQUEST = "GET_MED_TYPE_REQUEST"
export const GET_MED_TYPE_SUCCESS = "GET_MED_TYPE_SUCCESS"
export const GET_MED_TYPE_FAILURE = "GET_MED_TYPE_FAILURE"

export const GET_DIRECTION_REQUEST = "GET_DIRECTION_REQUEST"
export const GET_DIRECTION_SUCCESS = "GET_DIRECTION_SUCCESS"
export const GET_DIRECTION_FAILURE = "GET_DIRECTION_FAILURE"

export const DOWNLOAD_PRESCRIPTION_PDF_REQUEST = "DOWNLOAD_PRESCRIPTION_PDF_REQUEST"
export const DOWNLOAD_PRESCRIPTION_PDF_SUCCESS = "DOWNLOAD_PRESCRIPTION_PDF_SUCCESS"
export const DOWNLOAD_PRESCRIPTION_PDF_FAILURE = "DOWNLOAD_PRESCRIPTION_PDF_FAILURE"

export const GET_USER_INDIVIDUAL_REQUEST = "GET_USER_INDIVIDUAL_REQUEST"
export const GET_USER_INDIVIDUAL_SUCCESS = "GET_USER_INDIVIDUAL_SUCCESS"
export const GET_USER_INDIVIDUAL_FAILURE = "GET_USER_INDIVIDUAL_FAILURE"

export const GET_USER_PLAN_REQUEST = "GET_USER_PLAN_REQUEST"
export const GET_USER_PLAN_SUCCESS = "GET_USER_PLAN_SUCCESS"
export const GET_USER_PLAN_FAILURE = "GET_USER_PLAN_FAILURE"

export const GET_USER_PROGRAM_REQUEST = "GET_USER_PROGRAM_REQUEST"
export const GET_USER_PROGRAM_SUCCESS = "GET_USER_PROGRAM_SUCCESS"
export const GET_USER_PROGRAM_FAILURE = "GET_USER_PROGRAM_FAILURE"


export const ALL_HOSPITAL_NAME_DROPDOWN_REQUEST = "ALL_HOSPITAL_NAME_DROPDOWN_REQUEST"
export const ALL_HOSPITAL_NAME_DROPDOWN_SUCCESS = "ALL_HOSPITAL_NAME_DROPDOWN_SUCCESS"
export const ALL_HOSPITAL_NAME_DROPDOWN_FAILURE = "ALL_HOSPITAL_NAME_DROPDOWN_FAILURE"

export const GET_DOCTOR_LIST_REQUEST = "GET_DOCTOR_LIST_REQUEST"
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS"
export const GET_DOCTOR_LIST_FAILURE = "GET_DOCTOR_LIST_FAILURE"


export const HOSPITAL_VIEW_DOCTOR_REQUEST = "HOSPITAL_VIEW_DOCTOR_REQUEST"
export const HOSPITAL_VIEW_DOCTOR_SUCCESS = "HOSPITAL_VIEW_DOCTOR_SUCCESS"
export const HOSPITAL_VIEW_DOCTOR_FAILURE = "HOSPITAL_VIEW_DOCTOR_FAILURE"


export const HOSPITAL_DASHBOARD_REQUEST = "HOSPITAL_DASHBOARD_REQUEST"
export const HOSPITAL_DASHBOARD_SUCCESS = "HOSPITAL_DASHBOARD_SUCCESS"
export const HOSPITAL_DASHBOARD_FAILURE = "HOSPITAL_DASHBOARD_FAILURE"

export const VIEW_DROPDOWN_ACCOUNT_REQUEST = "VIEW_DROPDOWN_ACCOUNT_REQUEST"
export const VIEW_DROPDOWN_ACCOUNT_SUCCESS = "VIEW_DROPDOWN_ACCOUNT_SUCCESS"
export const VIEW_DROPDOWN_ACCOUNT_FAILURE = " VIEW_DROPDOWN_ACCOUNT_FAILURE"

export const ADD_USER_REQUEST = "ADD_USER_REQUEST"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAILURE = " ADD_USER_FAILURE"


export const CHECK_EMAIL_OR_NUMBER_REQUEST = "CHECK_EMAIL_OR_NUMBER_REQUEST"
export const CHECK_EMAIL_OR_NUMBER_SUCCESS = "CHECK_EMAIL_OR_NUMBER_SUCCESS"
export const CHECK_EMAIL_OR_NUMBER_FAILURE = "CHECK_EMAIL_OR_NUMBER_FAILURE"

export const CREATE_TALK_TO_DOCTOR_REQUEST = "CREATE_TALK_TO_DOCTOR_REQUEST"
export const CREATE_TALK_TO_DOCTOR_SUCCESS = "CREATE_TALK_TO_DOCTOR_SUCCESS"
export const CREATE_TALK_TO_DOCTOR_FAILURE = "CREATE_TALK_TO_DOCTOR_FAILURE"

export const GET_SERVICE_DROPDOWN_REQUEST = "GET_SERVICE_DROPDOWN_REQUEST"
export const GET_SERVICE_DROPDOWN_SUCCESS = "GET_SERVICE_DROPDOWN_SUCCESS"
export const GET_SERVICE_DROPDOWN_FAILURE = "GET_SERVICE_DROPDOWN_FAILURE"

export const GET_PACKAGE_DROPDOWN_REQUEST = "GET_PACKAGE_DROPDOWN_REQUEST"
export const GET_PACKAGE_DROPDOWN_SUCCESS = "GET_PACKAGE_DROPDOWN_SUCCESS"
export const GET_PACKAGE_DROPDOWN_FAILURE = "GET_PACKAGE_DROPDOWN_FAILURE"

export const GET_PLAN_DROPDOWN_REQUEST = "GET_PLAN_DROPDOWN_REQUEST"
export const GET_PLAN_DROPDOWN_SUCCESS = "GET_PLAN_DROPDOWN_SUCCESS"
export const GET_PLAN_DROPDOWN_FAILURE = "GET_PLAN_DROPDOWN_FAILURE"


export const CORPORATE_DROPDOWN_REQUEST = "CORPORATE_DROPDOWN_REQUEST"
export const CORPORATE_DROPDOWN_SUCCESS = "CORPORATE_DROPDOWN_SUCCESS"
export const CORPORATE_DROPDOWN_FAILURE = "CORPORATE_DROPDOWN_FAILURE"

export const PLANS_DROPDOWN_FOR_CARD_REQUEST = "PLANS_DROPDOWN_FOR_CARD_REQUEST"
export const PLANS_DROPDOWN_FOR_CARD_SUCCESS = "PLANS_DROPDOWN_FOR_CARD_SUCCESS"
export const PLANS_DROPDOWN_FOR_CARD_FAILURE = "PLANS_DROPDOWN_FOR_CARD_FAILURE"


export const CREATE_CARD_NUMBER_REQUEST = "CREATE_CARD_NUMBER_REQUEST"
export const CREATE_CARD_NUMBER_SUCCESS = "CREATE_CARD_NUMBER_SUCCESS"
export const CREATE_CARD_NUMBER_FAILURE = "CREATE_CARD_NUMBER_FAILURE"


export const GET_ALL_CARDS_DROPDOWN_REQUEST = "GET_ALL_CARDS_DROPDOWN_REQUEST"
export const GET_ALL_CARDS_DROPDOWN_SUCCESS = "GET_ALL_CARDS_DROPDOWN_SUCCESS"
export const GET_ALL_CARDS_DROPDOWN_FAILURE = "GET_ALL_CARDS_DROPDOWN_FAILURE"

export const ASSIGN_CARD_SUBSCRIPTION_REQUEST = "ASSIGN_CARD_SUBSCRIPTION_REQUEST"
export const ASSIGN_CARD_SUBSCRIPTION_SUCCESS = "ASSIGN_CARD_SUBSCRIPTION_SUCCESS"
export const ASSIGN_CARD_SUBSCRIPTION_FAILURE = "ASSIGN_CARD_SUBSCRIPTION_FAILURE"

export const GET_DOWNLOAD_CARD_DETAIL_REQUEST = "GET_DOWNLOAD_CARD_DETAIL_REQUEST"
export const GET_DOWNLOAD_CARD_DETAIL_SUCCESS = "GET_DOWNLOAD_CARD_DETAIL_SUCCESS"
export const GET_DOWNLOAD_CARD_DETAIL_FAILURE = "GET_DOWNLOAD_CARD_DETAIL_FAILURE"

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST"
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS"
export const UPDATE_PLAN_FAILURE = "UPDATE_PLAN_FAILURE"

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST"
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE"

export const EDIT_DISCOUNT_PRODUCT_REQUEST = "EDIT_DISCOUNT_PRODUCT_REQUEST"
export const EDIT_DISCOUNT_PRODUCT_SUCCESS = "EDIT_DISCOUNT_PRODUCT_SUCCESS"
export const EDIT_DISCOUNT_PRODUCT_FAILURE = "EDIT_DISCOUNT_PRODUCT_FAILURE"

export const MAP_TO_ELASTIC_REQUEST = "MAP_TO_ELASTIC_REQUEST"
export const MAP_TO_ELASTIC_SUCCESS = "MAP_TO_ELASTIC_SUCCESS"
export const MAP_TO_ELASTIC_FAILURE = "MAP_TO_ELASTIC_FAILURE"

export const MAP_TO_ELASTIC_DOCTOR_REQUEST = "MAP_TO_ELASTIC_DOCTOR_REQUEST"
export const MAP_TO_ELASTIC_DOCTOR_SUCCESS = "MAP_TO_ELASTIC_DOCTOR_SUCCESS"
export const MAP_TO_ELASTIC_DOCTOR_FAILURE = "MAP_TO_ELASTIC_DOCTOR_FAILURE"

export const ADD_YUVA_CLINIC_REQUEST = "ADD_YUVA_CLINIC_REQUEST"
export const ADD_YUVA_CLINIC_SUCCESS = "ADD_YUVA_CLINIC_SUCCESS"
export const ADD_YUVA_CLINIC_FAILURE = " ADD_YUVA_CLINIC_FAILURE"

export const VIEW_ALL_YUVA_CLINIC_REQUEST = "VIEW_ALL_YUVA_CLINIC_REQUEST"
export const VIEW_ALL_YUVA_CLINIC_SUCCESS = "VIEW_ALL_YUVA_CLINIC_SUCCESS"
export const VIEW_ALL_YUVA_CLINIC_FAILURE = "VIEW_ALL_YUVA_CLINIC_FAILURE"

export const VERIFY_USER_KYC_REQUEST = "VERIFY_USER_KYC_REQUEST"
export const VERIFY_USER_KYC_SUCCESS = "VERIFY_USER_KYC_SUCCESS"
export const VERIFY_USER_KYC_FAILURE = "VERIFY_USER_KYC_FAILURE"

