import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { viewAllCityRequest } from "../../../../redux/Actions/City/viewAllCityAction";
import { networkClinicRequest } from "../../../../redux/Actions/Network/Clinic/NetworkClinicAction";
import storage from "../../../../Utils/storage";
import AutoComplete from "../../../Molecules/Autocomplete/AutoComplete";
import Footer from "../../../Organisms/Footer/FooterUpper";
import "./YuvaClinics.css"
import ModalAddYuvaClinic from "../../../Organisms/Modal/ModalAddYuvaClinic";
import { viewAllYuvaClinicRequest } from "../../../../redux/Actions/Clinic/viewAllYuvaClinicAction"

function YuvaClinics() {
    const [add, setAdd] = useState(false);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [city, setCity] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchPage, setSearchPage] = useState(1);

    const viewCity = useSelector((state) => state.viewAllCity);
    const viewAllYuvaClinic = useSelector((state) => state.viewAllYuvaClinic);

    let cityNames = [{ label: "All cities", id: "" }];

    viewCity.data?.data?.data?.data.map((itm, ind) => {
        cityNames.push({
            label: itm.name,
            id: itm.id,
        });
    });
    const columns = [
        {
            field: "id",
            headerClassName: "super-app-theme--header",
            headerName: "Id",
            minWidth: 10,
            flex: .5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "clinicName",
            headerClassName: "super-app-theme--header",
            headerName: "Clinic Name",
            minWidth: 100,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerClassName: "super-app-theme--header",
            headerName: "City name",
            minWidth: 100,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "address",
            headerClassName: "super-app-theme--header",
            headerName: "Address",
            minWidth: 500,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
        },

    ];



    console.log("viewAllYuvaClinic", viewAllYuvaClinic);



    useEffect(() => {
        dispatch(viewAllCityRequest());
    }, []);

    useEffect(() => {
        dispatch(viewAllYuvaClinicRequest({ page, pageSize, searchText, city }));
    }, [city, page, pageSize]);


    console.log("city", city);
    let debounceTimer=null;

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setPage(1);
        if(debounceTimer){
            clearTimeout(debounceTimer);
        }
        else{
            debounceTimer= setTimeout(()=>{
        dispatch(viewAllYuvaClinicRequest({ page, pageSize, searchText, city }));
                
            },1000)
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
    };



    const handleCity = (e, val) => {
        setCity([val.id]);
    };

    const handlePageChange = (event, value) => {
        if (searchText === "" || searchText.length <= 1) {
            dispatch(viewAllYuvaClinicRequest(value, pageSize, searchText, city));
            setPage(value);
        } else {
            dispatch(viewAllYuvaClinicRequest(value, pageSize, searchText, city));
            setSearchPage(value);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleDropChange = (e) => {
        if (searchText === "" || searchText.length < 1) {
            dispatch(networkClinicRequest(page, e.target.value, searchText, city));
        } else {
            dispatch(
                networkClinicRequest(searchPage, e.target.value, searchText, city)
            );
        }
        setPageSize(e.target.value);
    };



    return (
        <div>
            {add && <ModalAddYuvaClinic open={setAdd} />}

            <div className="grid">
                <h4 className="h4">Yuva Clinics</h4>
                <div className="mainBox">
                    <div className="head">
                        <div className="leftHead">
                            <h5 className="list">List of Yuva Clinics</h5>
                            <div className="corporatecount">
                                {viewAllYuvaClinic.data?.data.data.data.totalCount} Request
                            </div>
                        </div>
                        <div className="rightHead">
                            <button onClick={() => setAdd(true)}>+ Add Yuva Clinic</button>
                        </div>
                    </div>
                    <hr />
                    <div className="row2">
                        <div className="requestCity">
                            <AutoComplete
                                onChange={(event, newValue) => {
                                    handleCity(event, newValue);
                                }}
                                heading="All Cities"
                                cityList={cityNames}
                            />
                        </div>
                        <div className="searchSection">
                            <div className="corporatesearchBar">
                                <TextField
                                    type="text"
                                    name="search"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    sx={{
                                        border: "1px solid #52608E",
                                        borderRadius: "6px",
                                        width: "350px",
                                    }}
                                    placeholder="Search"
                                    size="small"
                                    hiddenLabel="true"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ color: "#52608E" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
                        {viewAllYuvaClinic.data?.data.data.data.yuvaClinicDtoList && (
                            <DataGrid
                                sx={{
                                    color: "#1D2334",
                                    "& .super-app-theme--header": {
                                        backgroundColor: "#52608E",
                                        color: "#F2EFEA",
                                        "& > .MuiDataGrid-columnSeparator": {
                                            visibility: "hidden",
                                        },
                                    },
                                    "&:hover:not(.Mui-disabled)": {
                                        cursor: "pointer",
                                    },
                                    ".MuiTablePagination-toolbar": {
                                        alignItems: "baseline",
                                        marginTop: "5%",
                                    },
                                }}
                                pagination
                                disableColumnMenu
                                rowCount={
                                    viewAllYuvaClinic.data?.data.data.data.totalHospitalRequest
                                }
                                rows={
                                    viewAllYuvaClinic.data?.data.data.data
                                        .yuvaClinicDtoList
                                }
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 50, 100]}
                                onPageChange={(newPage) => setPage(newPage)}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                components={{
                                    Footer: "none",
                                }}
                            />
                        )}
                    </Box>

                    <div className="MainTablePagination">
                        <p>Rows Per Page:</p>
                        <select
                            className="dropDown"
                            defaultValue={10}
                        onChange={handleDropChange}
                        >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <Pagination
                            count={viewAllYuvaClinic.data?.data.data.data.totalPages}
                            color="primary"
                            onChange={handlePageChange}
                            page={
                                searchText === "" || searchText.length <= 1 ? page : searchPage
                            }
                        />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <Footer />
        </div>
    );
}

export default YuvaClinics;
